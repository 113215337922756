// extracted by mini-css-extract-plugin
export var actionsWrapper = "tournaments-module--actionsWrapper--g5Cgn";
export var bodyBase = "tournaments-module--body-base--OM0sU tournaments-module--site-font--zD1Ur";
export var bodyLarge = "tournaments-module--body-large--H7Pi- tournaments-module--body-base--OM0sU tournaments-module--site-font--zD1Ur";
export var bodyLargeBold = "tournaments-module--body-large-bold--nMsH0 tournaments-module--body-base--OM0sU tournaments-module--site-font--zD1Ur";
export var bodyRegular = "tournaments-module--body-regular--jgdCD tournaments-module--body-base--OM0sU tournaments-module--site-font--zD1Ur";
export var bodyRegularBold = "tournaments-module--body-regular-bold--dqs4L tournaments-module--body-base--OM0sU tournaments-module--site-font--zD1Ur";
export var bodySmall = "tournaments-module--body-small--6HGms tournaments-module--body-base--OM0sU tournaments-module--site-font--zD1Ur";
export var bodySmallBold = "tournaments-module--body-small-bold--5gF3x tournaments-module--body-base--OM0sU tournaments-module--site-font--zD1Ur";
export var borderTop = "tournaments-module--border-top--B7ycc";
export var breakWordContainer = "tournaments-module--break-word-container--b6YjX";
export var buttonIconBase = "tournaments-module--button-icon-base--MeHQ8";
export var buttonsContainer = "tournaments-module--buttonsContainer--fw9i7";
export var clickLink = "tournaments-module--click-link--e6cdr";
export var datesColumn = "tournaments-module--dates-column--Z7RTa";
export var dropdownBase = "tournaments-module--dropdown-base--iJhOp";
export var dropdownSelectBase = "tournaments-module--dropdown-select-base--+AbwL tournaments-module--text-input--6pZjP";
export var eventsLink = "tournaments-module--eventsLink--LyTrG";
export var eventsWidth = "tournaments-module--events-width--mWChH tournaments-module--small-col-base--rDAUB";
export var flexCol = "tournaments-module--flex-col--U8iLb";
export var formErrorMessage = "tournaments-module--form-error-message--nV8AF";
export var h3 = "tournaments-module--h3--nwqed";
export var h4 = "tournaments-module--h4--WqraJ";
export var hoverLink = "tournaments-module--hover-link--uwVl8";
export var hoverRow = "tournaments-module--hover-row--KAUa7";
export var id = "tournaments-module--id--hE2k0";
export var levelWidth = "tournaments-module--level-width--+x7v2 tournaments-module--small-col-base--rDAUB";
export var location = "tournaments-module--location--igIDe";
export var membershipContainer = "tournaments-module--membership-container--OnhwU tournaments-module--flex-col--U8iLb tournaments-module--primary-border--rwZnW";
export var membershipHeader = "tournaments-module--membership-header--gd-C-";
export var membershipHeading = "tournaments-module--membership-heading--ZGVUE";
export var membershipLabel = "tournaments-module--membership-label--fvDbD";
export var moreFiltersBorderClass = "tournaments-module--more-filters-border-class--k5cwS";
export var name = "tournaments-module--name--Rb63B";
export var nameAndLocation = "tournaments-module--name-and-location--GEvsl";
export var nameAndLocationWidth = "tournaments-module--name-and-location-width--SVY7I";
export var pageBg = "tournaments-module--page-bg--teMdI";
export var pointer = "tournaments-module--pointer--C4QAp";
export var primaryBorder = "tournaments-module--primary-border--rwZnW";
export var shadowBoxLight = "tournaments-module--shadow-box-light--Iobkr";
export var siteFont = "tournaments-module--site-font--zD1Ur";
export var slideDownAndFade = "tournaments-module--slideDownAndFade--389qL";
export var slideLeftAndFade = "tournaments-module--slideLeftAndFade--TWvtF";
export var slideRightAndFade = "tournaments-module--slideRightAndFade--mxBzP";
export var slideUpAndFade = "tournaments-module--slideUpAndFade--wfLDw";
export var smallColBase = "tournaments-module--small-col-base--rDAUB";
export var statusDecoration = "tournaments-module--status-decoration--RAvrT";
export var textInput = "tournaments-module--text-input--6pZjP";
export var textInverted = "tournaments-module--text-inverted--WmK8t";
export var textMediumDark = "tournaments-module--text-medium-dark--VFKUO";
export var titleDate = "tournaments-module--title-date--dE+he";
export var tooltipFont = "tournaments-module--tooltipFont--Mhe0W";
export var tournamentLogo = "tournaments-module--tournament-logo--EgZga tournaments-module--tournament-logo-base--sHNJf";
export var tournamentLogoBase = "tournaments-module--tournament-logo-base--sHNJf";
export var tournamentLogoPlaceholder = "tournaments-module--tournament-logo-placeholder--aUeGY tournaments-module--tournament-logo-base--sHNJf";
export var unstyledButton = "tournaments-module--unstyled-button--n5+hQ";