// extracted by mini-css-extract-plugin
export var body = "tournament-events-modal-module--body--gcskN";
export var bodyBase = "tournament-events-modal-module--body-base--Ot-uU tournament-events-modal-module--site-font--14BLj";
export var bodyLarge = "tournament-events-modal-module--body-large--HgTjt tournament-events-modal-module--body-base--Ot-uU tournament-events-modal-module--site-font--14BLj";
export var bodyLargeBold = "tournament-events-modal-module--body-large-bold--UFmPT tournament-events-modal-module--body-base--Ot-uU tournament-events-modal-module--site-font--14BLj";
export var bodyRegular = "tournament-events-modal-module--body-regular--B1qjb tournament-events-modal-module--body-base--Ot-uU tournament-events-modal-module--site-font--14BLj";
export var bodyRegularBold = "tournament-events-modal-module--body-regular-bold--J5Xer tournament-events-modal-module--body-base--Ot-uU tournament-events-modal-module--site-font--14BLj";
export var bodySmall = "tournament-events-modal-module--body-small--qyeC3 tournament-events-modal-module--body-base--Ot-uU tournament-events-modal-module--site-font--14BLj";
export var bodySmallBold = "tournament-events-modal-module--body-small-bold--2fBq9 tournament-events-modal-module--body-base--Ot-uU tournament-events-modal-module--site-font--14BLj";
export var borderTop = "tournament-events-modal-module--border-top--iNvlJ";
export var breakWordContainer = "tournament-events-modal-module--break-word-container--RzwhL";
export var buttonIconBase = "tournament-events-modal-module--button-icon-base--2BIQ+";
export var clickLink = "tournament-events-modal-module--click-link--OwIZc";
export var dropdownBase = "tournament-events-modal-module--dropdown-base--Yq5Ma";
export var dropdownSelectBase = "tournament-events-modal-module--dropdown-select-base--XiLtf tournament-events-modal-module--text-input--uRQxr";
export var eventsCount = "tournament-events-modal-module--eventsCount--Sq9bU";
export var flexCol = "tournament-events-modal-module--flex-col--DJHzE";
export var formErrorMessage = "tournament-events-modal-module--form-error-message--Kfs+D";
export var h3 = "tournament-events-modal-module--h3--vKfBp";
export var h4 = "tournament-events-modal-module--h4--v49he";
export var hoverLink = "tournament-events-modal-module--hover-link--1W5be";
export var hoverRow = "tournament-events-modal-module--hover-row--kYkZw";
export var membershipContainer = "tournament-events-modal-module--membership-container--oVV+k tournament-events-modal-module--flex-col--DJHzE tournament-events-modal-module--primary-border--ytOME";
export var membershipHeader = "tournament-events-modal-module--membership-header--Su90U";
export var membershipHeading = "tournament-events-modal-module--membership-heading--AUlgK";
export var membershipLabel = "tournament-events-modal-module--membership-label--KrSGz";
export var modalTitle = "tournament-events-modal-module--modalTitle--cqTWX";
export var moreFiltersBorderClass = "tournament-events-modal-module--more-filters-border-class--i1Ttw";
export var pageBg = "tournament-events-modal-module--page-bg--GVSiE";
export var pointer = "tournament-events-modal-module--pointer--Cqyoe";
export var primaryBorder = "tournament-events-modal-module--primary-border--ytOME";
export var shadowBoxLight = "tournament-events-modal-module--shadow-box-light--NYoKA";
export var siteFont = "tournament-events-modal-module--site-font--14BLj";
export var slideDownAndFade = "tournament-events-modal-module--slideDownAndFade--yz-qM";
export var slideLeftAndFade = "tournament-events-modal-module--slideLeftAndFade--jfK0x";
export var slideRightAndFade = "tournament-events-modal-module--slideRightAndFade--fprfi";
export var slideUpAndFade = "tournament-events-modal-module--slideUpAndFade--cKDO0";
export var statusDecoration = "tournament-events-modal-module--status-decoration--Dl6Ko";
export var textInput = "tournament-events-modal-module--text-input--uRQxr";
export var textInverted = "tournament-events-modal-module--text-inverted--6gqJy";
export var textMediumDark = "tournament-events-modal-module--text-medium-dark--Jh30D";
export var tooltipFont = "tournament-events-modal-module--tooltipFont--5gUXM";
export var unstyledButton = "tournament-events-modal-module--unstyled-button--FqI9x";