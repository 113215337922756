import React, { useState, useCallback, useMemo } from 'react'
import AutomaticDownload from '../generate-report/automatic-download'
import {
  OnReportUploadStateChange,
  ReportUploadState,
  CSVTransform,
  CSVValueTransform
} from '../generate-report/generate-report'
import { useTranslation } from 'react-i18next'
import { getEnvConfig } from 'src/config/config'
import { print } from 'graphql'
import FloatingNotification from '../floating-notification/floating-notification'
import * as styles from './tournaments-download-csv.module.less'
import { GetTournamentsVariables } from 'src/graphql-types/GetTournaments'
import { DOWNLOAD_PAGINATED_TOURNAMENTS } from './tournaments-download-csv.queries'
import { Grid } from '@material-ui/core'
import { TFunction } from 'i18next'
import { SanctionStatus, Category } from 'src/graphql-types/globalTournamentTypes'

export const getCsvTransforms = (t: TFunction): CSVTransform[] => [
  {
    key: 'identificationCode',
    label: t('tournament id')
  },
  {
    key: 'name',
    label: t('name')
  },
  {
    key: 'primaryLocation.address1',
    label: t('location')
  },
  {
    key: 'timings.startDate',
    label: t('start date')
  },
  {
    key: 'timings.endDate',
    label: t('end date')
  },
  {
    key: 'organisation.parent.parent.name',
    label: t('section')
  },
  {
    key: 'organisation.parent.name',
    label: t('district')
  },
  {
    key: 'director.firstName',
    label: t('tournament director'),
    transforms: [
      {
        operation: CSVValueTransform.ARRAY_FIELD_SELECT,
        parameters: [{ key: 'fieldPath', value: 'director.firstName' }]
      },
      {
        operation: CSVValueTransform.ARRAY_FIELD_SELECT,
        parameters: [{ key: 'fieldPath', value: 'director.lastName' }]
      },
      {
        operation: CSVValueTransform.ARRAY_JOIN,
        parameters: [{ key: 'delimiter', value: ' ' }]
      }
    ]
  },
  {
    key: 'level.name',
    label: t('level')
  },
  {
    key: 'level.category',
    label: t('circuit'),
    valueMap: [
      { in: Category.ADULT, out: t('adult') },
      { in: Category.JUNIOR, out: t('junior') },
      { in: Category.WHEELCHAIR, out: t('wheelchair') },
      { in: Category.WTN_PLAY, out: t('wtn play') }
    ]
  },
  {
    key: 'sanctionStatus',
    label: t('status'),
    valueMap: [
      { in: SanctionStatus.SUBMITTED, out: t('submitted') },
      { in: SanctionStatus.DECLINED, out: t('declined') },
      { in: SanctionStatus.APPROVED, out: t('approved') }
    ]
  },
  {
    key: 'isPublished',
    label: t('published status'),
    valueMap: [
      { in: 'TRUE', out: t('published') },
      { in: 'FALSE', out: t('not published') }
    ]
  }
]

export function TournamentsDownloadCsv({ variables }: { variables: GetTournamentsVariables }) {
  const { t } = useTranslation()
  const [downloadable, setDownloadable] = useState<boolean>(false)
  const [reportUploadState, setReportUploadState] = useState<ReportUploadState>('none')
  const [popupClosed, setPopupClosed] = useState(false)

  const onReportUploadStateChange = useCallback<OnReportUploadStateChange>(
    state => {
      if (state === 'processing') {
        setPopupClosed(false)
      }
      setReportUploadState(state)
    },
    [setReportUploadState, setPopupClosed]
  )

  const reportQueryVariables = useMemo(() => {
    const { orgId, ...queryVars } = variables
    return {
      ...queryVars,
      ...(orgId && { tournamentFilter: { sanctioningOrganisationId: orgId } })
    }
  }, [variables])

  return (
    <Grid container justifyContent="flex-end">
      <AutomaticDownload
        paginator={{ rootFieldPath: 'paginatedTournaments.items' }}
        reportUploadState={reportUploadState}
        onReportUploadStateChange={onReportUploadStateChange}
        generateButtonTitle={t('download csv')}
        downloadable={downloadable}
        setDownloadable={setDownloadable}
        reportQuery={print(DOWNLOAD_PAGINATED_TOURNAMENTS)}
        reportQueryEndpoint={getEnvConfig().TOURNAMENTS_GQL_URL}
        filename="tournaments"
        reportQueryVariables={reportQueryVariables}
        csvTransforms={getCsvTransforms(t)}
      />
      {reportUploadState === 'processing' && !popupClosed && (
        <FloatingNotification
          message={t('preparing download')}
          onClose={() => setPopupClosed(true)}
          variant="download"
          hideCloseButton
        />
      )}
      {reportUploadState === 'downloadable' && !popupClosed && (
        <FloatingNotification
          icon={{ name: 'md-tick-circle', className: styles.tick }}
          message={t('report downloaded')}
          variant="downloaded"
          onClose={() => setPopupClosed(true)}
        />
      )}
    </Grid>
  )
}
