import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { FILTER_OFF } from '../../utils/constants/global-constants'
import { Category } from '../../graphql-types/globalTournamentTypes'
import { useTranslation } from 'react-i18next'
import DropdownRadio from '../dropdown-radio/dropdown-radio'

interface Props {
  setFilters: (filter: any) => void
  category: Category
}

const DropdownCircuit: React.FC<Props> = ({ category, setFilters }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setFilters({
        category: value !== FILTER_OFF ? (value as Category) : undefined,
        levelId: undefined
      })
      toggleDropdown(false)
    },
    [setFilters]
  )

  const toggleDropdown = (value: boolean) => {
    setIsOpen(value)
  }

  const options = useMemo(() => {
    return [
      { label: t('any'), value: FILTER_OFF },
      { label: t('junior'), value: Category.JUNIOR },
      { label: t('adult'), value: Category.ADULT },
      { label: t('wheelchair'), value: Category.WHEELCHAIR },
      { label: t('wtn play'), value: Category.WTN_PLAY },
      { label: t('pickleball'), value: Category.PICKLE_BALL }
    ]
  }, [t])

  return (
    <DropdownRadio
      label={t('circuit')}
      onChange={handleChange}
      isOpen={isOpen}
      options={options}
      toggleDropdown={toggleDropdown}
      value={category ?? FILTER_OFF}
    />
  )
}

export default DropdownCircuit
