import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as styles from './tournaments.module.less'
import Panel from '../panel/panel'
import StatusLabel from '../status-label/status-label'
import { useQuery } from '@apollo/client'
import {
  GetTournaments,
  GetTournaments_paginatedTournamentGroups_items_tournaments as Tournament,
  GetTournamentsVariables
} from 'src/graphql-types/GetTournaments'
import Spinner from '../spinner/spinner'
import { useTranslation } from 'react-i18next'
import AdminTable, { DataCols, useColumnSelector } from 'src/components/admin-table/admin-table'
import { NoWrap, AlignNumber, PageMaxWidth } from 'src/components/util-components/util-components'
import SanctionStatusLabel from '../sanction-status-label/sanction-status-label'
import { navigate } from 'gatsby'
import { GET_TOURNAMENTS } from './tournaments-queries'
import {
  GroupingType,
  SanctionStatus,
  Operator,
  Category as CategoryType
} from 'src/graphql-types/globalTournamentTypes'
import moment from 'moment'
import TournamentFilters, {
  TournamentFilter,
  EventsEnum,
  SectionDistrictEmptyFilter
} from '../tournament-filters/tournament-filters'
import PageHeader from '../page-header/page-header'
import { useOrgId } from 'src/apollo/local-state'
import APIErrorMessage from '../api-error-message/api-error-message'
import { Category } from 'src/components/tournament-attributes/tournament-attributes'
import { graphQLDateString } from 'src/utils/helper/tournnaments-utils'
import EmptyState from '../empty-state/empty-state'
import Icon from '../icon/icon'
import { tournamentsClient } from 'src/apollo/client'
import { useDebounce } from 'src/hooks/use-debounce'
import { useOrgLevel, useOrgHierarchy } from 'src/utils/auth'
import { TournamentsDownloadCsv } from '../tournaments-download-csv/tournaments-download-csv'
import { useVenuesAndOrganisations } from './tournaments.api'
import GroupByFilter from '../tournament-filters/group-by-filter'
import { PaginationVariables, SortVariables, localSort } from '../table-controls/table-controls'
import _ from 'lodash'
import TournamentTableColumns from '../tournament-table-columns/tournament-table-columns'
import { retrieveColumnPreference } from '../../utils/storage/local-storage'
import TournamentEventsModal from '../tournament-events-modal/tournament-events-modal'

export function isIdentificationCode(search: string | undefined) {
  const [firstPart, secondPart] = search?.split('-') || []
  return firstPart?.length === 2 && secondPart?.length === 5
}

function getOrgIdVariable({
  section,
  district,
  orgId,
  orgHierarchy
}: {
  section: string | undefined
  district: string | undefined
  orgId: any
  orgHierarchy: string[] | undefined
}): Record<'orgId', string> | Record<any, never> {
  const [nationalId] = orgHierarchy || []
  if (district && district !== SectionDistrictEmptyFilter.ALL) {
    return { orgId: district }
  }

  if (section && section !== SectionDistrictEmptyFilter.ALL) {
    return { orgId: section }
  }

  if (section === SectionDistrictEmptyFilter.ALL) {
    return { orgId: nationalId }
  }

  if (orgId) {
    return { orgId }
  }

  return {}
}

const TABLE_ID = 'tournament_table'

const TournamentsPanel: React.FC = () => {
  const orgId = useOrgId()
  const orgHierarchy = useOrgHierarchy()
  const { data: venuesAndOrganisations } = useVenuesAndOrganisations()
  const { facilities, venues } = venuesAndOrganisations || {}
  const [groupBy, setGroupBy] = useState<GroupingType>(GroupingType.WEEK)
  const [eventsModal, setEventsModal] = useState<boolean>(false)
  const [selectedTournament, setSelectedTournament] = useState<SortableTournaments>()
  const storedColumnPreference = useMemo(() => retrieveColumnPreference(TABLE_ID), [])

  const initialFilter = useMemo<TournamentFilter>(
    () => ({
      from: moment().toDate(),
      to: moment()
        .add(3, 'months')
        .toDate(),
      sanctionStatus: [SanctionStatus.SUBMITTED],
      category: CategoryType.JUNIOR,
      section: SectionDistrictEmptyFilter.ALL,
      district: SectionDistrictEmptyFilter.ALL
    }),
    []
  )

  const [filter, setFilter] = useState<TournamentFilter>(initialFilter)
  const debouncedSearch = useDebounce(filter.search, 500)
  const filtersWithDebouncedSearch = useMemo(() => ({ ...filter, search: debouncedSearch }), [
    filter,
    debouncedSearch
  ])

  const tournamentVariables = useMemo<GetTournamentsVariables>(() => {
    const {
      to,
      from,
      sanctionStatus,
      levelId,
      category,
      section,
      district,
      search,
      events,
      ...f
    } = filtersWithDebouncedSearch

    const identificationCode = isIdentificationCode(search)

    const filters: GetTournamentsVariables['filters'] = []

    if (!(sanctionStatus as any).includes('ANY')) {
      filters.push({
        property: 'sanctionStatus',
        operator: Operator.IN,
        values: sanctionStatus
      })
    }

    if (levelId) {
      filters.push({
        property: 'restrictedToLevelId',
        operator: Operator.IN,
        values: levelId
      })
    }

    if (identificationCode) {
      filters.push({
        property: 'identificationCode',
        operator: Operator.EQUALS,
        values: [search]
      })
    }

    const isFacility = facilities?.find(f => f.ID === search)
    const isVenue = venues?.find(f => f.ID === search)

    if (search && isVenue && !identificationCode) {
      filters.push({
        property: 'organisationId',
        operator: Operator.EQUALS,
        values: [search]
      })
    }

    if (search && isFacility && !identificationCode) {
      filters.push({
        property: 'primaryLocationId',
        operator: Operator.EQUALS,
        values: [search]
      })
    }

    if (events && events === EventsEnum.TEAM) {
      filters.push({
        property: 'hasTeamEvents',
        operator: Operator.EQUALS,
        values: ['true']
      })
    }

    if (category && (category as any) !== 'ANY') {
      filters.push({
        property: 'levelCategories',
        operator: Operator.ANY,
        values: [category]
      })
    }

    const vars: GetTournamentsVariables = {
      ...f,
      startDateTo: graphQLDateString(to),
      startDateFrom: graphQLDateString(from),
      groupBy,
      ...getOrgIdVariable({ section, district, orgId, orgHierarchy }),
      filters
    }

    return vars
  }, [filtersWithDebouncedSearch, orgId, venues, facilities, orgHierarchy, groupBy])

  const { loading, error, data } = useQuery<GetTournaments, GetTournamentsVariables>(
    GET_TOURNAMENTS,
    { client: tournamentsClient, variables: tournamentVariables, fetchPolicy: 'no-cache' }
  )

  const { t: tl } = useTranslation()

  // Filter out all tournaments that don't require sanctioning, then filter out
  // all groups with no remaining tournaments in them
  const visibleGroups = useMemo(() => {
    return data?.paginatedTournamentGroups.items
      .map(group => {
        return { ...group, tournaments: group.tournaments.filter(t => t.sanctionStatus) }
      })
      .filter(g => !!g.tournaments.length)
  }, [data])

  const cols: DataCols<SortableTournaments> = useMemo(
    () => [
      {
        key: 'name',
        title: tl('tournament'),
        getValue: t => <NameAndLocation tournament={t} />,
        widthClassName: styles.nameAndLocationWidth,
        sort: groupBy === GroupingType.ALL,
        columnToggle: {
          checked: storedColumnPreference?.['name'] ?? true
        }
      },
      {
        key: 'startDate',
        title: tl('dates'),
        getValue: t => (
          <NoWrap>
            {tl('tournament dates', { start: moment(t.startDate), end: moment(t.endDate) })}
          </NoWrap>
        ),
        widthClassName: styles.datesColumn,
        sort: groupBy === GroupingType.ALL,
        columnToggle: {
          checked: storedColumnPreference?.['startDate'] ?? true
        }
      },
      {
        key: 'section',
        title: tl('section'),
        getValue: t => t.section,
        sort: groupBy === GroupingType.ALL,
        columnToggle: {
          checked: storedColumnPreference?.['section'] ?? true
        }
      },
      {
        key: 'district',
        title: tl('district'),
        getValue: t => t.district,
        sort: groupBy === GroupingType.ALL,
        columnToggle: {
          checked: storedColumnPreference?.['district'] ?? true
        }
      },
      {
        key: 'directorName',
        title: tl('tournament director'),
        getValue: t => t.directorName,
        sort: groupBy === GroupingType.ALL,
        columnToggle: {
          checked: storedColumnPreference?.['directorName'] ?? true
        }
      },
      {
        key: 'eventCount',
        title: tl('events'),
        getValue: t => (
          <a href="#" className={styles.eventsLink} onClick={e => openEventsModal(e, t)}>{`${
            t.eventCount
          } ${t.eventCount > 1 ? tl('events') : tl('event')}`}</a>
        ),
        widthClassName: styles.eventsWidth,
        sort: groupBy === GroupingType.ALL,
        columnToggle: {
          checked: storedColumnPreference?.['eventCount'] ?? true
        }
      },
      {
        key: 'levelName',
        title: tl('level'),
        getValue: t => <AlignNumber val={t.levelName} />,
        widthClassName: styles.levelWidth,
        sort: groupBy === GroupingType.ALL,
        columnToggle: {
          checked: storedColumnPreference?.['levelName'] ?? true
        }
      },
      {
        key: 'category',
        title: tl('circuit'),
        getValue: t => <Category category={t.category} />,
        sort: groupBy === GroupingType.ALL,
        columnToggle: {
          checked: storedColumnPreference?.['category'] ?? true
        }
      },
      {
        key: 'sanctionStatus',
        title: tl('status'),
        getValue: t => <SanctionStatusLabel status={t.sanctionStatus} cancelled={t.isCancelled} />,
        sort: groupBy === GroupingType.ALL,
        columnToggle: {
          checked: storedColumnPreference?.['sanctionStatus'] ?? true
        }
      },
      {
        key: 'isPublished',
        title: tl('published'),
        getValue: t => (t.isPublished ? <Icon name={'md-tick-circle'} /> : '—'),
        sort: groupBy === GroupingType.ALL,
        columnToggle: {
          checked: storedColumnPreference?.['isPublished'] ?? true
        }
      }
    ],
    [tl, groupBy]
  )

  const openEventsModal = (
    event: React.MouseEvent<HTMLAnchorElement>,
    tournament: SortableTournaments
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setSelectedTournament(tournament)
    setEventsModal(prevState => !prevState)
  }

  const closeEventsModal = () => {
    setSelectedTournament(undefined)
    setEventsModal(false)
  }

  const { colsToggle, setColsToggle } = useColumnSelector(cols, TABLE_ID)

  return (
    <PageMaxWidth>
      {/* <PagingControls numPages={4} /> */}
      <PageHeader title={tl('tournament list')} />
      <Panel>
        <section>
          <TournamentFilters onFilterChange={setFilter} initFilter={initialFilter} />
        </section>
      </Panel>
      <Panel>
        <div className={styles.actionsWrapper}>
          <GroupByFilter onGroupByChange={setGroupBy} value={groupBy} />
          <div className={styles.buttonsContainer}>
            <TournamentTableColumns columns={colsToggle} setCols={setColsToggle} />
            <TournamentsDownloadCsv variables={tournamentVariables} />
          </div>
        </div>
        {loading && <Spinner />}
        <APIErrorMessage error={error?.message} />
        {!loading &&
          !error &&
          visibleGroups &&
          (!visibleGroups.length ? (
            <EmptyStateComponent hasInputFilter={Boolean(filtersWithDebouncedSearch.search)} />
          ) : (
            visibleGroups.map(group => {
              return (
                <React.Fragment key={group.startDate}>
                  <Panel.Title>
                    {groupBy !== GroupingType.ALL && (
                      <StatusLabel>
                        {`${groupBy === GroupingType.WEEK ? tl('week abrv') : tl('month abrv')} ${
                          group.groupNumber
                        }`}
                      </StatusLabel>
                    )}
                    <span className={styles.titleDate}>
                      {tl('title dates', {
                        dates: { start: moment(group.startDate), end: moment(group.endDate) }
                      })}
                    </span>
                  </Panel.Title>
                  <TournamentsTableWrap tournaments={group.tournaments} cols={colsToggle} />
                </React.Fragment>
              )
            })
          ))}
        <TournamentEventsModal
          show={eventsModal}
          tournament={selectedTournament}
          closeEventsModal={closeEventsModal}
        />
      </Panel>
    </PageMaxWidth>
  )
}

function EmptyStateComponent({ hasInputFilter }: { hasInputFilter: boolean }) {
  const { t } = useTranslation()

  if (hasInputFilter) {
    return (
      <EmptyState
        title={t('no tournaments search title')}
        subtitle={t('no tournaments search description')}
        icon={'xl-tournament'}
      />
    )
  }

  return (
    <EmptyState
      title={t('no tournaments title')}
      subtitle={t('no tournaments subtitle')}
      icon={'xl-tournament'}
    />
  )
}

interface TableProps {
  tournaments: Tournament[]
  cols: DataCols<SortableTournaments>
}

export interface SortableTournaments extends Tournament {
  startDate: string
  endDate: string
  section: string
  district: string
  directorName: string | null
  levelName: string
  category: CategoryType | undefined
}

const TournamentsTableWrap: React.FC<TableProps> = ({ tournaments, cols }) => {
  const [flatTournaments, setFlatTournaments] = useState<SortableTournaments[]>([])
  const { t: tl } = useTranslation()

  useEffect(() => {
    //  This remapping is for sorting to work
    //  (in order to sort nested values we need to change sort function)
    //  (this way we expose values in top level so we dont need to change sorting function for each value)
    const sortableTournaments: SortableTournaments[] = tournaments.map(t => ({
      ...t,
      startDate: t.timings.startDate,
      endDate: t.timings.endDate,
      section: (t.organisation?.parent as any)?.parent?.name ?? tl('n/a'),
      district: t.organisation?.parent?.name ?? tl('n/a'),
      directorName: t.director && `${t.director.firstName} ${t.director.lastName}`,
      levelName: t.level?.shortName || tl('n/a'),
      category: t.level?.category
    }))
    setFlatTournaments(sortableTournaments)
  }, [tournaments])

  const onControlChange = useCallback(
    (controls: { pagination?: PaginationVariables; sorting?: SortVariables }) => {
      let dataToSort = [...flatTournaments]
      //
      if (controls.sorting) {
        dataToSort = localSort(dataToSort, controls.sorting.orderBy, controls.sorting.order)
      }
      // onControlChange triggers on every rerender, so we need to set
      // state only if object differs to avoid loop.
      if (!_.isEqual(flatTournaments, dataToSort)) {
        setFlatTournaments(dataToSort)
      }
    },
    [flatTournaments]
  )

  return (
    <AdminTable
      fixedWidth
      columns={cols}
      data={flatTournaments}
      onRowClick={t => navigate(`/tournaments/${t.id}`)}
      spacing={{ margins: { lg: 'bottom' } }}
      controls={{ sortDisabled: false, onControlChange: onControlChange, paginaitonDisabled: true }}
    />
  )
}

interface NameAndLocationProps {
  tournament: Tournament
}

const NameAndLocation: React.FC<NameAndLocationProps> = ({ tournament }) => {
  const { isDistrict } = useOrgLevel()
  return (
    <div className={styles.nameAndLocation}>
      {tournament.websiteContent?.logoPath ? (
        <img src={tournament.websiteContent.logoPath} className={styles.tournamentLogo}></img>
      ) : (
        <div className={styles.tournamentLogoPlaceholder} />
      )}
      <div>
        <div className={styles.name}>{tournament.name}</div>
        <div className={styles.location}>{tournament.organisation?.name}</div>
        {!isDistrict && <div className={styles.id}>{tournament.identificationCode}</div>}
      </div>
    </div>
  )
}

export default TournamentsPanel
