import React, { useMemo } from 'react'
import { Footer, Modal } from '../modal/modal'
import * as styles from './tournament-events-modal.module.less'
import { SortableTournaments } from '../tournaments/tournaments'
import { useTranslation } from 'react-i18next'
import Button from '../button/button'
import { useQuery } from '@apollo/client'
import { GetEvents, GetEvents_tournament_events as Event } from '../../graphql-types/GetEvents'
import { GET_EVENTS_OVERVIEW } from '../events/events-queries'
import { tournamentsClient } from '../../apollo/client'
import AdminTable, { DataCols } from '../admin-table/admin-table'
import useEventSort from '../../hooks/use-event-sort'
import { EventName } from '../tournament-attributes/tournament-attributes'
import Spinner from '../spinner/spinner'
import { GetEventsOverview, GetEventsOverviewVariables } from 'src/graphql-types/GetEventsOverview'

interface TournamentEventsModalProps {
  show: boolean
  tournament: SortableTournaments | undefined
  closeEventsModal: () => void
}
const TournamentEventsModal: React.FC<TournamentEventsModalProps> = ({
  show,
  tournament,
  closeEventsModal
}) => {
  const { t } = useTranslation()

  const { loading, data } = useQuery<GetEventsOverview, GetEventsOverviewVariables>(
    GET_EVENTS_OVERVIEW,
    {
      client: tournamentsClient,
      variables: { id: tournament?.id }
    }
  )

  const events = useEventSort(data as GetEvents)
  const cols: DataCols<Event> = useMemo(() => {
    const columns: DataCols<Event> = [
      { key: 'event', title: t('event'), getValue: e => <EventName event={e} /> },
      { key: 'players', title: t('players'), getValue: e => e?.formatConfiguration?.drawSize }
    ]

    return columns
  }, [t])

  return (
    <Modal show={show} sectionClassname={styles.section}>
      <h3 className={styles.modalTitle}>{tournament?.name}</h3>
      <p className={styles.eventsCount}>
        {tournament?.eventCount}{' '}
        {tournament && tournament?.eventCount > 1 ? t('events') : t('event')}
      </p>
      {loading && <Spinner />}
      {!loading && events && (
        <div className={styles.body}>
          <AdminTable columns={cols} data={events} />
        </div>
      )}
      <Footer>
        <Button level="tertiary" onClick={closeEventsModal}>
          {t('close')}
        </Button>
      </Footer>
    </Modal>
  )
}

export default TournamentEventsModal
