import React from 'react'
import Dropdown from '../dropdown/dropdown'
import { GroupingType } from '../../graphql-types/globalTournamentTypes'
import { useTranslation } from 'react-i18next'

interface GroupByFilterProps {
  onGroupByChange: (value: GroupingType) => void
  value: GroupingType
}

const GroupByFilter: React.FC<GroupByFilterProps> = ({ onGroupByChange, value }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Dropdown
        selected={value}
        options={[
          { label: t('week'), value: GroupingType.WEEK },
          { label: t('month'), value: GroupingType.MONTH },
          { label: t('ungrouped'), value: GroupingType.ALL }
        ]}
        onSelect={o => onGroupByChange(o.value)}
      />
    </div>
  )
}

export default GroupByFilter
