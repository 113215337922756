import React, { useCallback, useEffect, useState } from 'react'
import { MoreFilters as MoreFiltersComponent } from '@clubspark-react/ui'
import { Category, SanctionStatus } from '../../graphql-types/globalTournamentTypes'
import MoreFiltersLevel from '../dropdown-level/more-filters-level'
import { useTranslation } from 'react-i18next'
import {
  EventsEnum,
  SectionDistrictEmptyFilter,
  TournamentFilter
} from '../tournament-filters/tournament-filters'
import MoreFiltersCircuit from '../dropdown-circuit/more-filters-circuit'
import MoreFiltersStatus from '../dropdown-status/more-filters-status'
import { FILTER_OFF } from '../../utils/constants/global-constants'
import { GetLevels_levels } from '../../graphql-types/GetLevels'
import MoreFiltersSectionDistrict from './more-filters-section-district'
import MoreFiltersEvents from './more-filters-events'

interface MoreFiltersProps {
  setFilters: (filter: any) => void
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  initialFilter: TournamentFilter
  category?: Category
  selectedLevel: string[]
  status_all: SanctionStatus[]
  status: SanctionStatus | typeof FILTER_OFF
  levelData: GetLevels_levels[] | undefined
  section: string | undefined
  district: string | undefined
  events: EventsEnum | undefined
}

const MoreFilters: React.FC<MoreFiltersProps> = ({
  isOpen,
  setIsOpen,
  setFilters,
  initialFilter,
  category,
  selectedLevel,
  status_all,
  levelData,
  status,
  section,
  district,
  events
}) => {
  const [selectedCircuit, setSelectedCircuit] = useState<Category | undefined>()
  const [selectedLevels, setSelectedLevels] = useState<string[]>([FILTER_OFF])
  const [selectedStatus, setSelectedStatus] = useState<SanctionStatus | typeof FILTER_OFF>(
    SanctionStatus.SUBMITTED
  )
  const [selectedSection, setSelectedSection] = useState<string>()
  const [selectedDistrict, setSelectedDistrict] = useState<string>()
  const [selectedEvents, setSelectedEvents] = useState<EventsEnum | undefined>()
  const { t } = useTranslation()

  //  Since we are using `apply filters` button and not onChange
  //  filters can get out of sync (if user changes more filters but doesn't press apply button)
  const syncFilters = useCallback(() => {
    setSelectedCircuit(category)
    setSelectedLevels(selectedLevel)
    setSelectedStatus(status)
    setSelectedSection(section)
    setSelectedDistrict(district)
    setSelectedEvents(events ? events : EventsEnum.ALL)
  }, [category, selectedLevel, status, section, district, events])

  useEffect(() => {
    syncFilters()
  }, [syncFilters])

  const handleCircuitChange = useCallback(value => {
    setSelectedCircuit(value)
    setSelectedLevels([FILTER_OFF])
  }, [])

  const handleSectionChange = useCallback(value => {
    setSelectedSection(value)
    setSelectedDistrict(SectionDistrictEmptyFilter.ALL)
  }, [])

  const handleSubmit = useCallback(() => {
    setFilters({
      category: selectedCircuit,
      levelId: selectedLevels.includes(FILTER_OFF) ? undefined : selectedLevels,
      sanctionStatus: selectedStatus === FILTER_OFF ? status_all : [selectedStatus],
      section: selectedSection,
      district: selectedDistrict,
      events: selectedEvents === EventsEnum.ALL ? undefined : selectedEvents
    })

    setIsOpen(false)
  }, [
    setFilters,
    selectedCircuit,
    selectedLevels,
    selectedStatus,
    selectedSection,
    selectedDistrict,
    selectedEvents
  ])

  return (
    <MoreFiltersComponent
      fullWidth={false}
      applyBtnText={t('apply filters')}
      open={isOpen}
      title={t('more filters')}
      onCloseClick={() => {
        setIsOpen(false)
      }}
      clearBtnText={t('clear all filters')}
      onApplyBtnClick={handleSubmit}
      onClearBtnClick={() => {
        setFilters({
          ...initialFilter,
          levelId: undefined,
          events: undefined
        })
      }}
    >
      <MoreFiltersCircuit onChange={handleCircuitChange} selectedCircuit={selectedCircuit} />
      <MoreFiltersLevel
        levelData={levelData}
        setSelectedLevels={setSelectedLevels}
        selectedLevels={selectedLevels}
        selectedCircuit={selectedCircuit}
      />
      <MoreFiltersStatus selected={selectedStatus} onChange={setSelectedStatus} />
      <MoreFiltersEvents onChange={setSelectedEvents} selected={selectedEvents} />
      <MoreFiltersSectionDistrict
        selectedSection={selectedSection}
        setSelectedSection={handleSectionChange}
        selectedDistrict={selectedDistrict}
        setSelectedDistrict={setSelectedDistrict}
      />
    </MoreFiltersComponent>
  )
}

export default MoreFilters
