import React, { useMemo } from 'react'
import { FilterAccordion } from '@clubspark-react/ui'
import { useTranslation } from 'react-i18next'
import { useOrgLevel } from '../../utils/auth'
import { SectionDistrictEmptyFilter } from '../tournament-filters/tournament-filters'
import {
  getDistrictOrSectionOptions,
  useSectionAndDistricts
} from '../../hooks/use-section-and-districts'
import * as styles from '../../styles/shared.module.less'
import { OptionFilter } from '@clubspark-react/ui/dist/components/more-filters/FilterAccordion'
import InfoTooltip from '../info-tooltip/info-tooltip'

interface MoreFiltersSectionDistrictProps {
  selectedSection: string | undefined
  selectedDistrict: string | undefined
  setSelectedSection: (value: string | undefined) => void
  setSelectedDistrict: (value: string | undefined) => void
}

const MoreFiltersSectionDistrict: React.FC<MoreFiltersSectionDistrictProps> = ({
  selectedSection,
  selectedDistrict,
  setSelectedSection,
  setSelectedDistrict
}) => {
  const { t } = useTranslation()
  const { isSection, isDistrict } = useOrgLevel()
  const {
    sections,
    sectionOptions: defaultSectionOptions,
    districtOptions: defaultDistrictOptions
  } = useSectionAndDistricts(selectedSection)

  const sectionOptions = useMemo(() => {
    if (!sections) return [{ label: t('section all'), value: SectionDistrictEmptyFilter.ALL }]
    const options = [
      { label: t('section all'), value: SectionDistrictEmptyFilter.ALL },
      ...getDistrictOrSectionOptions(sections)
    ]
    if (isSection || isDistrict) return options
    return defaultSectionOptions?.map(s =>
      s.value === '' ? { ...s, value: SectionDistrictEmptyFilter.ALL } : s
    )
  }, [sections, isSection, isDistrict, defaultSectionOptions, t])

  const districtOptions = useMemo(
    () =>
      defaultDistrictOptions?.map(d =>
        d.value === '' ? { ...d, value: SectionDistrictEmptyFilter.ALL } : d
      ),
    [defaultDistrictOptions]
  )

  const noDistrictOptions = useMemo(() => {
    return (
      districtOptions?.length === 1 && districtOptions[0].value === SectionDistrictEmptyFilter.ALL
    )
  }, [districtOptions])

  return (
    <>
      <FilterAccordion
        clearValue={SectionDistrictEmptyFilter.ALL}
        filterValue="section"
        label={t('section')}
        className={styles.moreFiltersBorderClass}
        onChange={l => {
          const value = l.reduce((prev, current) => prev + current, '')
          setSelectedSection(value)
        }}
        options={
          sectionOptions || [{ label: t('section all'), value: SectionDistrictEmptyFilter.ALL }]
        }
        values={[selectedSection as string]}
      />
      <FilterAccordion
        EmptyComponent={<p>{t('district empty')}</p>}
        InfoTooltipComponent={<InfoTooltip text={t('district tooltip')} />}
        clearValue={SectionDistrictEmptyFilter.ALL}
        filterValue="district"
        label={t('district')}
        className={styles.moreFiltersBorderClass}
        onChange={l => {
          const value = l.reduce((prev, current) => prev + current, '')
          setSelectedDistrict(value)
        }}
        options={noDistrictOptions ? [] : (districtOptions as OptionFilter[])}
        values={[selectedDistrict as string]}
      />
    </>
  )
}

export default MoreFiltersSectionDistrict
