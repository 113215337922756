import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { FILTER_OFF } from '../../utils/constants/global-constants'
import { Category, SanctionStatus } from '../../graphql-types/globalTournamentTypes'
import { useTranslation } from 'react-i18next'
import DropdownRadio from '../dropdown-radio/dropdown-radio'

interface Props {
  setFilters: (filter: any) => void
  selected: SanctionStatus | typeof FILTER_OFF
  status_all: SanctionStatus[]
}

const DropdownStatus: React.FC<Props> = ({ setFilters, selected, status_all }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const status = event.target.value as SanctionStatus | typeof FILTER_OFF
      let statusFilter: SanctionStatus[]
      if (status === FILTER_OFF) {
        statusFilter = status_all
      } else {
        statusFilter = [status]
      }
      setFilters({ sanctionStatus: statusFilter })
      toggleDropdown(false)
    },
    [setFilters]
  )

  const toggleDropdown = (value: boolean) => {
    setIsOpen(value)
  }

  const options = useMemo(() => {
    return [
      { label: t('any'), value: FILTER_OFF },
      { label: t('pending'), value: SanctionStatus.SUBMITTED },
      { label: t('approved'), value: SanctionStatus.APPROVED },
      { label: t('declined'), value: SanctionStatus.DECLINED }
    ]
  }, [t])

  return (
    <DropdownRadio
      label={t('status')}
      onChange={handleChange}
      isOpen={isOpen}
      options={options}
      toggleDropdown={toggleDropdown}
      value={selected}
    />
  )
}

export default DropdownStatus
