import React, { ChangeEvent } from 'react'
import RadioButtonGroup from '../radio-button-group/radio-button-group'
import { FILTER_OFF } from '../../utils/constants/global-constants'
import { Category, SanctionStatus } from '../../graphql-types/globalTournamentTypes'
import DropdownContainer from '../dropdown-custom/dropdown-container'
import { useTranslation } from 'react-i18next'

interface Props {
  label: string
  options: { label: string; value: string }[]
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  isOpen: boolean
  toggleDropdown: (value) => void
  value: Category | typeof FILTER_OFF | SanctionStatus
}

const DropdownRadio: React.FC<Props> = ({
  label,
  options,
  onChange,
  isOpen,
  toggleDropdown,
  value
}) => {
  return (
    <DropdownContainer
      label={label}
      width={100}
      icon="down-icon"
      iconPosition="right"
      isOpen={isOpen}
      onToggleDropdown={toggleDropdown}
    >
      <RadioButtonGroup
        options={options}
        value={value}
        data-test-id="circuit-select"
        handleChange={onChange}
      />
    </DropdownContainer>
  )
}

export default DropdownRadio
