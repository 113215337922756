import React from 'react'
import { FilterAccordion } from '@clubspark-react/ui'
import { useTranslation } from 'react-i18next'
import * as styles from '../../styles/shared.module.less'
import { EventsEnum } from '../tournament-filters/tournament-filters'

interface DropdownCircuitMoreFiltersProps {
  selected: EventsEnum | undefined
  onChange: (value: EventsEnum | undefined) => void
}

const MoreFiltersEvents: React.FC<DropdownCircuitMoreFiltersProps> = ({ selected, onChange }) => {
  const { t } = useTranslation()

  return (
    <FilterAccordion
      clearValue={EventsEnum.ALL}
      filterValue="events"
      label={t('events')}
      className={styles.moreFiltersBorderClass}
      onChange={l => {
        const value = l.reduce((prev, current) => prev + current, '')
        onChange(value as EventsEnum)
      }}
      options={[
        { label: t('all events'), value: EventsEnum.ALL },
        { label: t('team events only'), value: EventsEnum.TEAM }
      ]}
      values={[selected as string]}
    />
  )
}

export default MoreFiltersEvents
