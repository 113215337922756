import React, { useMemo } from 'react'
import { Category, SanctionStatus } from '../../graphql-types/globalTournamentTypes'
import { useTranslation } from 'react-i18next'
import { FILTER_OFF } from '../../utils/constants/global-constants'
import Tag from './tag'
import {
  EventsEnum,
  SectionDistrictEmptyFilter,
  TournamentFilter
} from '../tournament-filters/tournament-filters'
import { useSectionAndDistricts } from '../../hooks/use-section-and-districts'
import * as styles from './filter-tag.module.less'
import _ from 'lodash'

interface FilterTagProps {
  category: Category
  setFilters: (filter: any) => void
  selectedLevels: string[] | string
  levelsOptions: { label: string; value: any }[]
  selectedStatus: SanctionStatus | typeof FILTER_OFF
  status_all: SanctionStatus[]
  section: string | undefined
  district: string | undefined
  events: EventsEnum | undefined
  initFilter: TournamentFilter
  from: Date | null
  to: Date | null
}

const FilterTag: React.FC<FilterTagProps> = ({
  category,
  setFilters,
  selectedLevels,
  levelsOptions,
  selectedStatus,
  status_all,
  section,
  district,
  events,
  initFilter,
  from,
  to
}) => {
  const { t } = useTranslation()

  const selectedLevelsLabels = useMemo(() => {
    const labelsArray: string[] = []

    selectedLevels !== FILTER_OFF &&
      levelsOptions.forEach(level => {
        if (selectedLevels.includes(level.value)) labelsArray.push(level.label)
      })

    return labelsArray
  }, [selectedLevels, levelsOptions])

  const selectedStatusLabels = useMemo(() => {
    return selectedStatus === SanctionStatus.SUBMITTED ? 'PENDING' : selectedStatus
  }, [selectedStatus])

  const { sections, districts } = useSectionAndDistricts(section)

  const selectedSectionLabel = useMemo(() => {
    return sections?.find(s => s.ID === section)?.Name
  }, [sections, section])

  const selectedDistrictLabel = useMemo(() => {
    return districts?.find(d => d.ID === district)?.Name
  }, [districts, district])

  const categoryLabel = useMemo(() => {
    const categoryStrings = {
      [Category.WTN_PLAY]: 'wtn play',
      [Category.ADULT]: 'adult',
      [Category.JUNIOR]: 'junior',
      [Category.WHEELCHAIR]: 'wheelchair',
      [Category.PICKLE_BALL]: 'pickleball'
    }
    return [t(categoryStrings[category]).toUpperCase()]
  }, [category, t])

  //  Check if filter values are the same as default values
  const areDefaultValues = useMemo(() => {
    const valueObject: any = {
      from,
      to,
      sanctionStatus: [selectedStatus],
      category,
      section,
      district
    }
    if (selectedLevels.length && selectedLevels !== FILTER_OFF) valueObject.levels = selectedLevels
    if (section && section !== SectionDistrictEmptyFilter.ALL) valueObject.section = section
    if (district && district !== SectionDistrictEmptyFilter.ALL) valueObject.district = district
    if (events && events !== EventsEnum.ALL) valueObject.events = events

    return _.isEqual(initFilter, valueObject)
  }, [initFilter, from, to, selectedStatus, category, section, district, selectedLevels, events])

  return (
    <>
      {category && (
        <Tag
          title={t('circuit')}
          data={categoryLabel}
          handleClose={() => setFilters({ category: undefined, levelId: undefined })}
        />
      )}
      {selectedLevels !== FILTER_OFF && (
        <Tag
          title={t('level')}
          data={selectedLevelsLabels}
          handleClose={() => setFilters({ levelId: undefined })}
        />
      )}
      {selectedStatus !== FILTER_OFF && (
        <Tag
          title={t('status')}
          data={[selectedStatusLabels]}
          handleClose={() => setFilters({ sanctionStatus: status_all })}
        />
      )}
      {section && section !== SectionDistrictEmptyFilter.ALL && (
        <Tag
          title={t('section')}
          data={[selectedSectionLabel as string]}
          handleClose={() =>
            setFilters({
              section: SectionDistrictEmptyFilter.ALL,
              district: SectionDistrictEmptyFilter.ALL
            })
          }
        />
      )}
      {district && district !== SectionDistrictEmptyFilter.ALL && (
        <Tag
          title={t('district')}
          data={[selectedDistrictLabel as string]}
          handleClose={() => setFilters({ district: SectionDistrictEmptyFilter.ALL })}
        />
      )}
      {events && events !== EventsEnum.ALL && (
        <Tag
          title={t('events')}
          data={events === EventsEnum.TEAM ? [t('team events only')] : []}
          handleClose={() => setFilters({ events: undefined })}
        />
      )}
      {!areDefaultValues && (
        <a
          href="#"
          className={styles.clearFiltersLink}
          onClick={e => {
            e.preventDefault()
            setFilters({
              ...initFilter,
              levelId: undefined,
              events: undefined
            })
          }}
        >
          {t('reset filters')}
        </a>
      )}
    </>
  )
}

export default FilterTag
