import TextField from '@material-ui/core/TextField'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { useTranslation } from 'react-i18next'
import { Category, SanctionStatus, SanctionType } from 'src/graphql-types/globalTournamentTypes'
import useFilters, { PARAM_TYPES, UrlParams } from 'src/hooks/use-filters'
import { FILTER_OFF } from 'src/utils/constants/global-constants'
import FilterSet, { Filter } from '../filter-set/filter-set'
import { useVenuesAndOrganisations } from '../tournaments/tournaments.api'
import * as styles from './tournament-filters.module.less'
import DropdownCustom from '../dropdown-custom/dropdown-custom'
import Button from '../button/button'
import DropdownCircuit from '../dropdown-circuit/dropdown-circuit'
import DropdownLevel from '../dropdown-level/dropdown-level'
import MoreFilters from '../more-filters/more-filters'
import FilterTag from '../filter-tag/filter-tag'
import { useQuery } from '@apollo/client'
import { GetLevels } from '../../graphql-types/GetLevels'
import { GET_LEVELS } from '../levels-dropdown/levels-dropdown-queries'
import { tournamentsClient } from '../../apollo/client'
import DropdownStatus from '../dropdown-status/dropdown-status'
import { useDefaultSectionAndDistrict } from '../../utils/helper/useDefaultSectionAndDistrict'

const STATUS_ALL = [SanctionStatus.APPROVED, SanctionStatus.DECLINED, SanctionStatus.SUBMITTED]
export enum EventsEnum {
  ALL = 'all',
  TEAM = 'team'
}

export enum SectionDistrictEmptyFilter {
  ALL = 'all'
}

export interface TournamentFilter {
  sanctionStatus: SanctionStatus[]
  category: Category
  to: Date
  from: Date
  levelId?: string[]
  section?: string
  district?: string
  search?: string
  events?: EventsEnum
}

interface Props {
  onFilterChange?: (filter: TournamentFilter) => any
  initFilter: TournamentFilter
}

function findSelectedValue(
  options: { label: string; value: string }[],
  searchFilter: string | undefined
) {
  return options.find(o => o.value === searchFilter)
}

const filter = createFilterOptions()

function useOrganisationAndVenueOptionsCallback() {
  return useCallback(
    (input: string, options: { value: string; label: string }[] | undefined = []) => {
      if (input.length < 3) return []
      return options?.filter(o => o.label.toLowerCase().includes(input.toLowerCase()))
    },
    []
  )
}

const TournamentFilters: React.FC<Props> = ({ onFilterChange, initFilter }) => {
  const [moreFilters, setMoreFilters] = useState<boolean>(false)
  const { t } = useTranslation()
  const toValue = useCallback((f: string) => f.toUpperCase(), [])
  const toUrlParam = useCallback((f: string) => f.toLowerCase(), [])
  const { options, loading: loadingOptions } = useVenuesAndOrganisations()
  const [input, setInput] = useState('')
  const getOrganisationAndVenueOptions = useOrganisationAndVenueOptionsCallback()

  const filteredOptions = useMemo(() => {
    return getOrganisationAndVenueOptions(input, options)
  }, [input, getOrganisationAndVenueOptions, options])

  const urlParams = useMemo<UrlParams<TournamentFilter>[]>(
    () => [
      {
        filterKey: 'section',
        paramName: 'section',
        toUrlParam,
        toValue: toUrlParam
      },
      { filterKey: 'district', paramName: 'district', toUrlParam, toValue: toUrlParam },
      {
        filterKey: 'search',
        paramName: 'search',
        toUrlParam,
        toValue: toUrlParam
      },
      {
        filterKey: 'events',
        paramName: 'events',
        toUrlParam,
        toValue: toUrlParam
      },
      { filterKey: 'from', paramType: PARAM_TYPES.DATE },
      { filterKey: 'to', paramType: PARAM_TYPES.DATE },
      { filterKey: 'category', toUrlParam, toValue },
      {
        filterKey: 'levelId',
        paramName: 'level',
        paramType: PARAM_TYPES.ARRAY,
        toUrlParam: levelIdToUrlHackyConversion,
        toValue: urlToLevelIdHackyConversion
      },
      {
        filterKey: 'sanctionStatus',
        paramName: 'status',
        toUrlParam: (f: SanctionStatus[]) => {
          if (f === STATUS_ALL) return 'any'
          else if (f[0] !== SanctionStatus.SUBMITTED) return f[0].toLowerCase()
        },
        toValue: p => (p === 'any' ? STATUS_ALL : [p.toUpperCase()])
      }
    ],
    []
  )

  const [
    { to, from, category, sanctionStatus, levelId, section, district, search, events },
    setFilters
  ] = useFilters({
    initialFilter: initFilter,
    onChange: onFilterChange,
    urlParams
  })

  const { data: levelData, loading: loadingLevels } = useQuery<GetLevels>(GET_LEVELS, {
    client: tournamentsClient
  })

  const levelsOptions = useMemo(() => {
    const l = levelData?.levels
    if (!l) return [{ label: t('loading levels'), value: 'loading' }]
    return [
      { label: t('any'), value: FILTER_OFF },
      ...l
        .filter(l => l.sanctionType !== SanctionType.NONE && l.category === category)
        .sort((l1, l2) => l1.orderIndex - l2.orderIndex)
        .map(l => ({ label: l.name, value: l.id }))
    ]
  }, [levelData, category, t])

  const defaultSectionDistrict = useDefaultSectionAndDistrict()
  useEffect(() => {
    if (defaultSectionDistrict) {
      const { defaultDistrict, defaultSection } = defaultSectionDistrict
      setFilters({ section: defaultSection, district: defaultDistrict })
    }
  }, [defaultSectionDistrict])

  return (
    <>
      <FilterSet>
        <Filter>
          <div style={{ width: 250 }}>
            <Autocomplete
              loading={loadingOptions}
              value={findSelectedValue(options, search) || undefined}
              inputValue={input}
              options={filteredOptions}
              getOptionLabel={(option: { value: string; label: string }) => {
                return option?.label || ''
              }}
              onInputChange={(_, newInputValue) => {
                setInput(newInputValue)
              }}
              onChange={(_, newValue) => {
                if (typeof newValue === 'string') {
                  setFilters({
                    search: newValue
                  })
                } else {
                  setFilters({
                    search: newValue?.value || ''
                  } as any)
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params as any)

                const { inputValue } = params
                // Suggest the creation of a new value
                const isExisting = options.some(option => inputValue === option.label)

                function checkFormat(str: string | undefined) {
                  return str && /^[0-9-]+$/.test(str)
                }

                const isValid = checkFormat(inputValue)
                if (inputValue !== '' && !isExisting && isValid) {
                  filtered.push({
                    value: inputValue,
                    label: inputValue
                  })
                }

                return filtered as any
              }}
              selectOnFocus
              clearOnBlur
              noOptionsText={input?.length < 3 ? t('enter at least 3 characters') : t('no options')}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    className={styles.textFieldRoot}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        notchedOutline: styles.notchedOutline
                      }
                    }}
                  />
                )
              }}
              classes={{
                root: styles.root,
                inputRoot: styles.inputRoot,
                input: styles.input,
                inputFocused: styles.focused,
                popupIndicator: styles.icon
              }}
            />
          </div>
        </Filter>
        <DropdownCustom from={from} setFilters={setFilters} to={to} initFilter={initFilter} />
        <DropdownCircuit setFilters={setFilters} category={category} />
        <DropdownLevel
          selected={levelId ?? FILTER_OFF}
          setFilters={setFilters}
          levelsOptions={levelsOptions}
          loadingLevels={loadingLevels}
        />
        <DropdownStatus
          setFilters={setFilters}
          selected={sanctionStatus === STATUS_ALL ? FILTER_OFF : sanctionStatus[0]}
          status_all={STATUS_ALL}
        />
        <MoreFilters
          isOpen={moreFilters}
          setIsOpen={setMoreFilters}
          setFilters={setFilters}
          initialFilter={initFilter}
          category={category}
          selectedLevel={levelId ?? [FILTER_OFF]}
          levelData={levelData?.levels}
          status={sanctionStatus === STATUS_ALL ? FILTER_OFF : sanctionStatus[0]}
          status_all={STATUS_ALL}
          section={section}
          district={district}
          events={events}
        />
        <Filter>
          <Button level="tertiary" onClick={() => setMoreFilters(true)}>
            {t('more filters')}
          </Button>
        </Filter>
      </FilterSet>
      <FilterTag
        category={category}
        setFilters={setFilters}
        selectedLevels={levelId ?? FILTER_OFF}
        selectedStatus={sanctionStatus === STATUS_ALL ? FILTER_OFF : sanctionStatus[0]}
        status_all={STATUS_ALL}
        levelsOptions={levelsOptions}
        section={section}
        district={district}
        events={events}
        from={from}
        to={to}
        initFilter={initFilter}
      />
    </>
  )
}

// format level id e.g: 10000000-0000-0000-0000-0000000000B6
// replace the zero section with 'z' -> 1zB6
const levelIdToUrlHackyConversion = (levelId: string[]) => {
  return levelId.map(id => {
    return id.replace(/(0+-*)+/, 'z')
  })
}

const urlToLevelIdHackyConversion = (urlParam: string[]) => {
  return urlParam.map(p => {
    const [before, after] = p.split('z')
    return (
      before +
      '00000000-0000-0000-0000-000000000000'.slice(before.length).slice(0, -after.length) +
      after
    )
  })
}

export default TournamentFilters
