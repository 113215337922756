import { useOrgId } from '../../apollo/local-state'
import { useSectionAndDistricts, Venue } from '../../hooks/use-section-and-districts'
import { OrgLevelEnum, useOrgLevel } from '../auth'
import { useMemo } from 'react'
import { SectionDistrictEmptyFilter } from '../../components/tournament-filters/tournament-filters'

function getInitialSectionFilter(
  sections: Venue[] | null,
  districts: Venue[] | null,
  orgId: string,
  level: OrgLevelEnum | undefined
) {
  switch (level) {
    case OrgLevelEnum.NATIONAL:
      return SectionDistrictEmptyFilter.ALL
    case OrgLevelEnum.SECTION:
      return orgId
    case OrgLevelEnum.DISTRICT: {
      const district = districts?.find(d => d.ID === orgId)
      const section = sections?.find(s => s.ID === district?.GroupID)
      return section?.ID
    }
  }
}

function getInitialDistrictFilter(orgId: string, level: OrgLevelEnum | undefined) {
  switch (level) {
    case OrgLevelEnum.NATIONAL:
      return SectionDistrictEmptyFilter.ALL
    case OrgLevelEnum.SECTION:
      return SectionDistrictEmptyFilter.ALL
    case OrgLevelEnum.DISTRICT:
      return orgId
  }
}
export const useDefaultSectionAndDistrict = () => {
  const orgId = useOrgId()
  const { sections, districts } = useSectionAndDistricts()
  const { level } = useOrgLevel()

  const defaultDistrict = useMemo(() => {
    if (orgId && level !== undefined) {
      return getInitialDistrictFilter(orgId, level)
    }
  }, [orgId, level])

  const defaultSection = useMemo(() => {
    if (sections && districts && orgId && level !== undefined) {
      return getInitialSectionFilter(sections, districts, orgId, level)
    }
  }, [orgId, level, sections, districts])

  return useMemo(() => {
    if (defaultSection && defaultDistrict) {
      return { defaultSection, defaultDistrict }
    }
  }, [defaultSection, defaultDistrict])
}
