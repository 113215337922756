import React from 'react'
import * as styles from './filter-tag.module.less'
import { TagFilter } from '@clubspark-react/ui'

interface TagProps {
  handleClick?: () => void
  handleClose: () => void
  data: string[]
  title: string
}

const Tag: React.FC<TagProps> = ({ handleClick, data, handleClose, title }) => {
  const onClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    handleClose()
  }
  return (
    <div className={styles.hoverComponent} onClick={handleClick}>
      <TagFilter data={data} displayedNumber={3} title={title} onCloseClick={e => onClose(e)} />
    </div>
  )
}

export default Tag
