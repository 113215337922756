import React from 'react'
import { FILTER_OFF } from '../../utils/constants/global-constants'
import { SanctionStatus } from '../../graphql-types/globalTournamentTypes'
import { FilterAccordion } from '@clubspark-react/ui'
import { useTranslation } from 'react-i18next'
import * as styles from '../../styles/shared.module.less'

interface DropdownCircuitMoreFiltersProps {
  selected: SanctionStatus | typeof FILTER_OFF
  onChange: (value: SanctionStatus | typeof FILTER_OFF) => void
}

const MoreFiltersStatus: React.FC<DropdownCircuitMoreFiltersProps> = ({ selected, onChange }) => {
  const { t } = useTranslation()

  return (
    <FilterAccordion
      clearValue={FILTER_OFF}
      filterValue="status"
      label={t('status')}
      className={styles.moreFiltersBorderClass}
      onChange={l => {
        const value = l.reduce((prev, current) => prev + current, '')
        const status = value as SanctionStatus | typeof FILTER_OFF
        onChange(status)
      }}
      options={[
        { label: t('any'), value: FILTER_OFF },
        { label: t('pending'), value: SanctionStatus.SUBMITTED },
        { label: t('approved'), value: SanctionStatus.APPROVED },
        { label: t('declined'), value: SanctionStatus.DECLINED }
      ]}
      values={[selected]}
    />
  )
}

export default MoreFiltersStatus
