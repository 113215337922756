import React, { useEffect, useMemo, useState } from 'react'
import { FILTER_OFF } from '../../utils/constants/global-constants'
import DropdownContainer from '../dropdown-custom/dropdown-container'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../formik-fields/formik-fields'
import * as styles from './dropdown-level.module.less'

interface Props {
  setFilters: (filter: any) => void
  selected: string[] | string
  onChange?: (level: string) => void
  levelsOptions: { label: string; value: any }[]
  loadingLevels: boolean
}

const DropdownLevel: React.FC<Props> = ({ selected, loadingLevels, setFilters, levelsOptions }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedLevels, setSelectedLevels] = useState<string[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    if (selected.length) setSelectedLevels(selected === FILTER_OFF ? [selected] : (selected as []))
  }, [selected])

  const onChange = (isChecked, value) => {
    //  Remove all values but 'FILTER_OFF' when 'any' option is selected
    if (isChecked && value === FILTER_OFF) {
      setSelectedLevels([value])
      setFilters({ levelId: undefined })
      return
    }

    let values: string[]
    if (isChecked) {
      values = [...selectedLevels.filter(v => v !== FILTER_OFF), value]
      setSelectedLevels(values)
    } else {
      values = [...selectedLevels]
      values.splice(values.indexOf(value), 1)
      setSelectedLevels(values.length ? values : [FILTER_OFF])
    }
    setFilters({ levelId: values.length ? values : undefined })
  }

  const toggleDropdown = (value: boolean) => {
    setIsOpen(value)
  }

  const noOptions = useMemo(() => {
    return !loadingLevels && levelsOptions.length === 1 && levelsOptions[0].value === FILTER_OFF
  }, [loadingLevels, levelsOptions])

  return (
    <DropdownContainer
      label={loadingLevels ? t('loading levels') : t('level')}
      width={100}
      icon="down-icon"
      iconPosition="right"
      isOpen={isOpen}
      onToggleDropdown={toggleDropdown}
      minWidth={200}
    >
      {noOptions && <div className={styles.noOptions}>{t('empty levels')}</div>}
      {!noOptions &&
        levelsOptions?.map(({ label, value }) => (
          <div key={value}>
            <Checkbox
              disabled={value === FILTER_OFF && selectedLevels.includes(value)}
              key={value}
              label={label}
              checked={selectedLevels.includes(value)}
              onChecked={isChecked => onChange(isChecked, value)}
            />
          </div>
        ))}
    </DropdownContainer>
  )
}

export default DropdownLevel
