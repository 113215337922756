import { useEffect, useMemo, useCallback } from 'react'
import { useState } from 'react'
import { fetchVenues, fetchFacilities } from 'src/utils/classic-api'

interface Facility {
  Address1: string
  Address2: string
  AuthorityID: string
  Category: number
  Classification: number
  County: string
  EmailAddress: string
  ExternalID: string
  GroupVenueName: string
  HasDomain: boolean
  ID: string
  IsoTimeZone: string
  Name: string
  PhoneNumber: string
  Postcode: string
  TimeZoneID: string
  Town: string
}

interface Venue {
  Address1: string
  Address2: string
  AuthorityID: string
  Category: number
  Classification: number
  County: string
  GroupVenueName: string
  HasDomain: boolean
  ID: string
  IsoTimeZone: string
  LtaID: string
  Name: string
  Postcode: string
  TimeZoneID: string
  Town: string
  UrlSegment: string
  Website: string
}

type Data = { venues: Venue[] | undefined; facilities: Facility[] | undefined } | undefined

export function useVenueAndOrganisationOptions() {
  return useCallback(
    (search: string, options: { value: string; label: string; type: OptionType }[]) => {
      return options?.filter(o => o.label.includes(search))
    },
    []
  )
}

export function useVenuesAndOrganisations() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Data>(undefined)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const venues = await fetchVenues()
      const facilities = await fetchFacilities()
      setData({ venues, facilities })
      setLoading(false)
    })()
  }, [])

  const options = useMemo(() => getOptions(data), [data])

  return useMemo(() => ({ loading, data, options }), [loading, data, options])
}

type OptionType = 'venue' | 'facility'

function getOptions(data: Data): { value: string; label: string; type: OptionType }[] {
  const { venues = [], facilities = [] } = data || {}
  const venueOptions = venues.map(v => ({
    label: v.Name,
    value: v.ID,
    type: 'venue' as OptionType
  }))
  const facilityOptions = facilities.map(f => ({
    label: f.Name,
    value: f.ID,
    type: 'facility' as OptionType
  }))
  return [...venueOptions, ...facilityOptions]
}
