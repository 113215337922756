import gql from 'graphql-tag'

export const DOWNLOAD_PAGINATED_TOURNAMENTS = gql`
  query DownloadPaginatedTournaments(
    $limit: Int!
    $skip: Int!
    $filters: [PropertyFilterInput!]!
    $sorts: [PropertySortInput!]
    $tournamentFilter: TournamentFilterInput
  ) {
    paginatedTournaments(
      tournamentFilter: $tournamentFilter
      paginationOptions: { offset: $skip, limit: $limit, filters: $filters, sorts: $sorts }
    ) {
      items {
        identificationCode
        name
        primaryLocation {
          address1
        }
        timings {
          startDate
          endDate
        }
        organisation {
          parent {
            name
            parent {
              name
            }
          }
        }
        director {
          firstName
          lastName
        }
        level {
          name
          category
        }
        sanctionStatus
        isPublished
      }
    }
  }
`
