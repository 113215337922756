import React from 'react'
import { FILTER_OFF } from '../../utils/constants/global-constants'
import { Category } from '../../graphql-types/globalTournamentTypes'
import { FilterAccordion } from '@clubspark-react/ui'
import { useTranslation } from 'react-i18next'
import * as styles from '../../styles/shared.module.less'

interface DropdownCircuitMoreFiltersProps {
  onChange: (value: string | undefined) => void
  selectedCircuit: string | undefined
}

const MoreFiltersCircuit: React.FC<DropdownCircuitMoreFiltersProps> = ({
  onChange,
  selectedCircuit
}) => {
  const { t } = useTranslation()

  return (
    <FilterAccordion
      clearValue={FILTER_OFF}
      filterValue="circuit"
      label={t('circuit')}
      className={styles.moreFiltersBorderClass}
      onChange={l => {
        const value = l.reduce((prev, current) => prev + current, '')
        onChange(value !== FILTER_OFF ? (value as Category) : undefined)
      }}
      options={[
        { label: t('any'), value: FILTER_OFF },
        { label: t('junior'), value: Category.JUNIOR },
        { label: t('adult'), value: Category.ADULT },
        { label: t('wheelchair'), value: Category.WHEELCHAIR },
        { label: t('wtn play'), value: Category.WTN_PLAY },
        { label: t('pickleball'), value: Category.PICKLE_BALL }
      ]}
      values={[selectedCircuit ?? FILTER_OFF]}
    />
  )
}

export default MoreFiltersCircuit
