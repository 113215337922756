import React, { createRef, useEffect, useState } from 'react'
import * as styles from './dropdown-custom.module.less'
import cx from 'classnames'
import Icon, { IconProps } from '../icon/icon'
import Button from '../button/button'
import { useTranslation } from 'react-i18next'

interface DropdownContainerProps {
  label?: string | undefined
  children?: React.ReactNode
  isOpen: boolean
  grayedLabel?: boolean
  onToggleDropdown: (value: boolean) => void
  icon?: string
  iconPosition?: 'left' | 'right'
  type?: 'button' | 'input'
  width?: number | string
  minWidth?: number
  buttonIconName?: IconProps['name']
}

const DropdownContainer: React.FC<DropdownContainerProps> = ({
  children,
  label,
  width,
  minWidth,
  isOpen,
  onToggleDropdown,
  icon,
  iconPosition,
  grayedLabel,
  type = 'input',
  buttonIconName
}) => {
  const ref = createRef<HTMLDivElement>()
  const { t } = useTranslation()
  //  Checks for outside clicks
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      onToggleDropdown(false)
    }
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref])

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation()
    onToggleDropdown(!isOpen)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        onToggleDropdown(true)
        break
      default:
        break
    }
  }

  return (
    <div
      ref={ref}
      className={cx({
        [styles.container]: type === 'input'
      })}
      style={{ width: width ?? 260 }}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <div
        className={cx({
          [styles.inputStyle]: type === 'input',
          [styles.buttonStyle]: type === 'button'
        })}
      >
        {type === 'button' && (
          <Button level="tertiary" onClick={handleClick} icon={buttonIconName}>
            {label}
          </Button>
        )}
        {type === 'input' && (
          <div
            className={cx(styles.label, {
              [styles.labelFlex]: iconPosition === 'right',
              [styles.grayedOut]: isOpen && grayedLabel
            })}
            onClick={handleClick}
          >
            {icon && iconPosition === 'left' && (
              <img
                className={styles.icon}
                src={require(`src/images/${icon}.png`).default}
                alt="Calendar"
              />
            )}
            {label}
            {icon && iconPosition === 'right' && (
              <img
                className={styles.iconRight}
                src={require(`src/images/${icon}.png`).default}
                alt="Calendar"
              />
            )}
          </div>
        )}
        <div
          className={cx(styles.content, { [styles.show]: isOpen })}
          style={{ minWidth: minWidth }}
          onClick={e => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default DropdownContainer
