import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { FILTER_OFF } from '../../utils/constants/global-constants'
import { useTranslation } from 'react-i18next'
import { FilterAccordion } from '@clubspark-react/ui'
import { SanctionType } from '../../graphql-types/globalTournamentTypes'
import { GetLevels_levels } from '../../graphql-types/GetLevels'
import * as styles from '../../styles/shared.module.less'
import InfoTooltip from '../info-tooltip/info-tooltip'

interface MoreFiltersLevelProps {
  setSelectedLevels: Dispatch<SetStateAction<string[]>>
  selectedLevels: string[]
  levelData: GetLevels_levels[] | undefined
  selectedCircuit: string | undefined
}

const MoreFiltersLevel: React.FC<MoreFiltersLevelProps> = ({
  setSelectedLevels,
  levelData,
  selectedLevels,
  selectedCircuit
}) => {
  const { t } = useTranslation()

  const levelsOptions = useMemo(() => {
    const l = levelData
    if (!l) return [{ label: t('loading levels'), value: 'loading' }]
    return [
      { label: t('any'), value: FILTER_OFF },
      ...l
        .filter(l => l.sanctionType !== SanctionType.NONE && l.category === selectedCircuit)
        .sort((l1, l2) => l1.orderIndex - l2.orderIndex)
        .map(l => ({ label: l.name, value: l.id }))
    ]
  }, [levelData, selectedCircuit, t])

  const noOptions = useMemo(() => {
    return levelsOptions.length === 1 && levelsOptions[0].value === FILTER_OFF
  }, [levelsOptions])

  const onChange = values => {
    setSelectedLevels(values)
  }

  return (
    <FilterAccordion
      EmptyComponent={<p>{t('empty levels')}</p>}
      InfoTooltipComponent={<InfoTooltip text={t('levels tooltip')} />}
      filterValue="level"
      clearValue={FILTER_OFF}
      className={styles.moreFiltersBorderClass}
      label={t('level')}
      multiple
      onChange={onChange}
      options={noOptions ? [] : levelsOptions}
      values={selectedLevels}
    />
  )
}

export default MoreFiltersLevel
